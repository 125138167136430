import { Container, GlobalStyles, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  alertError,
  alertSuccess,
  ExtendedButton,
  makeStyles
} from "@placehires/react-component-library";
import { navigate } from "gatsby";
import React, { useState } from "react";
import { useAuthRedirect } from "../../hooks/authHooks";
import { useLoadingWrapper } from "../../hooks/loadingHooks";
import PublicLayout from "../../layout/PublicLayout";
import { sendPasswordResetLink } from "../../services/authService";

const PersonalAuth = () => {
  const { classes } = useStyles();
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [loadWrap, loading] = useLoadingWrapper();
  useAuthRedirect();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return loadWrap(async () => {
      try {
        await sendPasswordResetLink(email);
        setErrorEmail(false);
        await navigate("/login");
        alertSuccess("Password reset link sent. Please check your mail");
      } catch (err) {
        const data = err.response?.data;
        if (data && (data.code == "auth/user-not-found" || data.code == "auth/invalid-email")) {
          setErrorEmail(true);
        } else {
          setErrorEmail(false);
          alertError("Oops something went wrong");
        }
      }
    });
  };

  return (
    <PublicLayout noFooter seoTitle="Forgot Password">
      <GlobalStyles styles={`body { background-color: #fff }`} />
      <Container className={classes.container} maxWidth="xs">
        <form onSubmit={onSubmit}>
          <Typography component="h1" variant="h5" align="center">
            Forgot Password?
          </Typography>
          <Typography variant="subtitle1" className={classes.text}>
            Please enter your email, and we will send you a link to reset your password.
          </Typography>
          <TextField
            required
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            fullWidth
            label="Email Address"
            name="email"
            autoComplete="email"
            error={errorEmail}
            helperText={errorEmail && "Email does not exist"}
          />
          <ExtendedButton
            loaderSize={25}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            loading={loading}
          >
            Send reset link
          </ExtendedButton>
        </form>
      </Container>
    </PublicLayout>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    marginTop: theme.spacing(14),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  text: {
    margin: theme.spacing(1, 0, 2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default PersonalAuth;
